import md5 from "crypto-js/md5";

/**
 * 工具库，此库中的所有函数都是纯函数，不应该产生任何副作用
 */
export default {
  /**
   * 根据 UA 判断是否是微信浏览器
   * @param {string} UA user agent,浏览器标识
   */
  isWeChatBrowser(UA) {
    return UA.toLowerCase().includes("micromessenger");
  },

  /**
   * 根据 UA 判断是否是IOS
   * @param {string} UA user agent,浏览器标识
   */
  isIOS() {
    return navigator.userAgent.indexOf("iPhone") > -1 || navigator.userAgent.indexOf("Mac OS") > -1;
  },
  /**
   * 获取 iOS 的版本
   */
  getIOSVersion() {
    return navigator.userAgent.toLowerCase()
      .match(/cpu iphone os (.*?) like mac os/)[1]
      .replace(/_/g, ".");
  },
  /**
   * 获取微信版本
   */
  getWechatVersion() {
    let wechatInfo = navigator.userAgent.toLowerCase().match(/micromessenger\/([\d\.]+)/i)
    return wechatInfo[1];
  },
  getAndroidVersion() {
    let ua = navigator.userAgent.toLowerCase(); 
    var match = ua.match(/android\s([0-9\.]*)/);
    return match ? match[1] : false;
  },
  /**
   * 将服务器端订单对象格式化成本地订单对象
   * @param {object} order 服务器端订单对象
   */
  formatOrder(order) {
    return {
      daySeq: order.data.day_seq, //当日订单序号
      arriveTime: order.data.arrive_time, //送达时间
      num: order.order_id, //单号
      platformType: order.type, //平台类型
      status: order.status_text, //订单状态文本
      customerName: order.data.customer_name, //点餐人姓名
      customerPhone: order.data.customer_phone, //点餐人电话
      address: order.data.address, //地址
      remark: order.data.remark, //用户备注
      goods: order.data.goods.map(e => {
        //商品
        return {
          name: e.name,
          quantity: e.quantity,
          totalPrice: e.total_price
        };
      }),
      goodsCount: order.data.goods_count, //商品数量
      otherPrice: order.data.other.map(e => {
        //其它费用
        return {
          name: e.name,
          quantity: e.quantity,
          totalPrice: e.total_price
        };
      }),
      payWay: order.data.pay_way, //支付方式
      totalPrice: order.data.customer_pay, //总价
      income: order.data.income, //预计收入
      time: new Date(order.order_time * 1000), //下单时间,
      coordinates: [Number(order.data.longitude), Number(order.data.latitude)]
    };
  },
  /**
   * 日期格式化
   * @param {object} date Date 对象
   * @param {string} format 格式化字符串
   */
  dateFormat(date, format = "yyyy-MM-dd") {
    const unit = {
      "y+": date.getFullYear(),
      "M+": date.getMonth() + 1,
      "d+": date.getDate(),
      "h+": date.getHours(),
      "m+": date.getMinutes(),
      "s+": date.getSeconds()
    };

    for (let key in unit) {
      if (new RegExp(`(${key})`).test(format)) {
        const matched = RegExp.$1;
        format = format.replace(
          matched,
          matched.length === 1
            ? unit[key]
            : ("0000" + unit[key]).slice(-matched.length)
        );
      }
    }

    return format;
  },
  /**
     * 和PHP一样的时间戳格式化函数
     * @param  {string} format    格式
     * @param  {int}    timestamp 要格式化的时间 默认为当前时间
     * @return {string}           格式化的时间字符串
     */
  date(format, timestamp) {
    var a, jsdate = ((timestamp) ? new Date(timestamp * 1000) : new Date());
    var pad = function (n, c) {
      if ((n = n + "").length < c) {
        return new Array(++c - n.length).join("0") + n;
      } else {
        return n;
      }
    };
    var txt_weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var txt_ordin = { 1: "st", 2: "nd", 3: "rd", 21: "st", 22: "nd", 23: "rd", 31: "st" };
    var txt_months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var f = {
      // Day
      d: function () { return pad(f.j(), 2) },
      D: function () { return f.l().substr(0, 3) },
      j: function () { return jsdate.getDate() },
      l: function () { return txt_weekdays[f.w()] },
      N: function () { return f.w() + 1 },
      S: function () { return txt_ordin[f.j()] ? txt_ordin[f.j()] : 'th' },
      w: function () { return jsdate.getDay() },
      z: function () { return (jsdate - new Date(jsdate.getFullYear() + "/1/1")) / 864e5 >> 0 },
      // Week
      W: function () {
        var a = f.z(), b = 364 + f.L() - a;
        var nd2, nd = (new Date(jsdate.getFullYear() + "/1/1").getDay() || 7) - 1;
        if (b <= 2 && ((jsdate.getDay() || 7) - 1) <= 2 - b) {
          return 1;
        } else {
          if (a <= 2 && nd >= 4 && a >= (6 - nd)) {
            nd2 = new Date(jsdate.getFullYear() - 1 + "/12/31");
            return date("W", Math.round(nd2.getTime() / 1000));
          } else {
            return (1 + (nd <= 3 ? ((a + nd) / 7) : (a - (7 - nd)) / 7) >> 0);
          }
        }
      },
      // Month
      F: function () { return txt_months[f.n()] },
      m: function () { return pad(f.n(), 2) },
      M: function () { return f.F().substr(0, 3) },
      n: function () { return jsdate.getMonth() + 1 },
      t: function () {
        var n;
        if ((n = jsdate.getMonth() + 1) == 2) {
          return 28 + f.L();
        } else {
          if (n & 1 && n < 8 || !(n & 1) && n > 7) {
            return 31;
          } else {
            return 30;
          }
        }
      },
      // Year
      L: function () { var y = f.Y(); return (!(y & 3) && (y % 1e2 || !(y % 4e2))) ? 1 : 0 },
      //o not supported yet
      Y: function () { return jsdate.getFullYear() },
      y: function () { return (jsdate.getFullYear() + "").slice(2) },
      // Time
      a: function () { return jsdate.getHours() > 11 ? "pm" : "am" },
      A: function () { return f.a().toUpperCase() },
      B: function () {
        // peter paul koch:
        var off = (jsdate.getTimezoneOffset() + 60) * 60;
        var theSeconds = (jsdate.getHours() * 3600) + (jsdate.getMinutes() * 60) + jsdate.getSeconds() + off;
        var beat = Math.floor(theSeconds / 86.4);
        if (beat > 1000) beat -= 1000;
        if (beat < 0) beat += 1000;
        if ((String(beat)).length == 1) beat = "00" + beat;
        if ((String(beat)).length == 2) beat = "0" + beat;
        return beat;
      },
      g: function () { return jsdate.getHours() % 12 || 12 },
      G: function () { return jsdate.getHours() },
      h: function () { return pad(f.g(), 2) },
      H: function () { return pad(jsdate.getHours(), 2) },
      i: function () { return pad(jsdate.getMinutes(), 2) },
      s: function () { return pad(jsdate.getSeconds(), 2) },
      //u not supported yet
      // Timezone
      //e not supported yet
      //I not supported yet
      O: function () {
        var t = pad(Math.abs(jsdate.getTimezoneOffset() / 60 * 100), 4);
        if (jsdate.getTimezoneOffset() > 0) t = "-" + t; else t = "+" + t;
        return t;
      },
      P: function () { var O = f.O(); return (O.substr(0, 3) + ":" + O.substr(3, 2)) },
      //T not supported yet
      //Z not supported yet
      // Full Date/Time
      c: function () { return f.Y() + "-" + f.m() + "-" + f.d() + "T" + f.h() + ":" + f.i() + ":" + f.s() + f.P() },
      //r not supported yet
      U: function () { return Math.round(jsdate.getTime() / 1000) }
    };
    return format.replace(/[\\]?([a-zA-Z])/g, function (t, s) {
      let ret;
      if (t != s) {
        // escaped
        ret = s;
      } else if (f[s]) {
        // a date function exists
        ret = f[s]();
      } else {
        // nothing special
        ret = s;
      }
      return ret;
    });
  },
  /**
   * 生成文件名
   * @param {*} file file 对象
   */
  generateFileName(file) {
    return (
      "image/" +
      md5(file.name + Date.now()).toString() +
      "." +
      file.name.split(".").pop()
    );
  },
  /**
   * oss 直传
   * @param {*} ossConfig oss 的配置参数
   */
  async uploadFile(file, oss) {
    const url =
      (location.protocol === "https:" ? "https:" : "http:") + "//" + oss.host;

    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", oss.filename);
      formData.append("policy", oss.policy);
      formData.append("OSSAccessKeyId", oss.OSSAccessKeyId);
      formData.append("success_action_status", "200");
      formData.append("signature", oss.signature);
      formData.append("file", file); //append函数的第一个参数是后台获取数据的参数名,和html标签的input的name属性功能相同
      let xmlHttpReq = new XMLHttpRequest();
      if (xmlHttpReq !== null) {
        //设置回调，当请求的状态发生变化时，就会被调用
        xmlHttpReq.onreadystatechange = () => {
          // 上传成功
          if (xmlHttpReq.readyState === 4 && xmlHttpReq.status === 200) {
            const eTag = xmlHttpReq.getResponseHeader("etag");
            resolve({ url: oss.domain + oss.filename, eTag });
          }
        };

        xmlHttpReq.onerror = () => {
          reject(new Error("上传失败"));
        };

        xmlHttpReq.open("POST", url, true);
        xmlHttpReq.send(formData);
      }
    });
  },
  /**
   * 利用 canvas 对象对图片进行压缩
   * 此方法只能用于浏览器环境
   * @param {*} src 图片资源
   * @param {*} width 最大宽度
   * @param {*} height 最大高度
   * @param {*} orientation 拍摄姿态（若不为 undefined，则会进行相应的矫正）
   */
  async imageCompress(
    src,
    { width_limit, height_limit, type = "image/png", orientation }
  ) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const image = new Image();
    return new Promise((resolve, reject) => {
      image.onload = function () {
        if (width_limit == undefined && height_limit != undefined) {
          width_limit = this.width;
        }
        if (height_limit == undefined && width_limit != undefined) {
          height_limit = this.height;
        }

        let width_target, height_target;

        /**
         * 获取图片原始尺寸
         */
        const width_orig = this.width;
        const height_orig = this.height;

        if (
          width_limit &&
          height_limit &&
          (width_orig > width_limit || height_orig > height_limit)
        ) {
          /**
           * 需要缩放
           */
          const scale = Math.min(
            width_limit / width_orig,
            height_limit / height_orig
          );

          width_target = width_orig * scale;
          height_target = height_orig * scale;
        } else {
          /**
           * 不需要缩放
           */
          width_target = width_orig;
          height_target = height_orig;
        }
        let x = 0,
          canvas_width = width_target,
          y = 0,
          canvas_height = height_target,
          angle = 0;

        /**
         * 旋转矫正
         */
        if (orientation != undefined) {
          switch (orientation) {
            case 3:
              /**
               * 需要旋转 180°
               */
              angle = Math.PI;
              x = -width_target;
              y = -height_target;
              break;
            case 6:
              /**
               * 需要顺时针旋转 90°
               */
              angle = Math.PI / 2;
              y = -height_target;
              canvas_width = height_target;
              canvas_height = width_target;
              break;
            case 8:
              /**
               * 需要逆时针旋转 90°
               */
              angle = -Math.PI / 2;
              x = -width_target;
              canvas_width = height_target;
              canvas_height = width_target;
              break;
          }
        }

        canvas.width = canvas_width;
        canvas.height = canvas_height;

        context.rotate(angle);
        context.drawImage(this, x, y, width_target, height_target);
        const dataURL = canvas.toDataURL(type);
        canvas.toBlob(blob => {
          resolve({ dataURL, blob });
        }, type);
      };
      image.src = src;
    });
  },
  /**
   * 本地图片生成 Base64 Data URl
   * @param {*} file 图片文件对象
   */
  async imageFileToBase64(file) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = e => resolve(e.target.result);
      reader.onerror = e => reject(e);
      reader.readAsDataURL(file);
    });
  },
  /**
   * 图像旋转矫正
   * @param {*} src 图像资源
   * @param {*} orientation 拍摄姿态
   */
  async imageCorrect(src, orientation, type = "image/png", quantity = 1) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const image = new Image();
    return new Promise(async (resolve, reject) => {
      image.onload = function () {
        /**
         * 获取图片原始尺寸
         */
        let width = this.width;
        let height = this.height;
        let angle, x, y;
        switch (orientation) {
          case 3:
            /**
             * 需要旋转 180°
             */
            angle = Math.PI;
            x = -width;
            y = -height;
            canvas.width = width;
            canvas.height = height;
            break;
          case 6:
            /**
             * 需要顺时针旋转 90°
             */
            angle = Math.PI / 2;
            x = 0;
            y = -height;
            canvas.width = height;
            canvas.height = width;
            break;
          case 8:
            /**
             * 需要逆时针旋转 90°
             */
            angle = -Math.PI / 2;
            x = -width;
            y = 0;
            canvas.width = height;
            canvas.height = width;
            break;
          default:
            /**
             * 不需要旋转
             */
            angle = 0;
            x = 0;
            y = 0;
            canvas.width = width;
            canvas.height = height;
            break;
        }

        context.rotate(angle);
        context.drawImage(this, x, y, width, height);

        resolve(canvas.toDataURL(type, quantity));
      };
      image.src = src;
    });
  },
};
