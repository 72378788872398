import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import requestor from "@/utils/requestor";
import api from "@/api";
import GlobalFilter from "@/utils/filters";
import tools from "@/utils/tools.js";


Vue.config.productionTip = false

Vue.use(Vant);

/**
 * 注入网络请求库
 */
Vue.prototype.$requestor = requestor;

/**
 * 设置 requestor 的错误处理函数
 */
Vue.prototype.$requestor.errorHandle[401] = () => {
  /**
   * 当出现 401 错误时，需要跳转到授权页进行授权
   * 为了避免未知的错误，在进行页面跳转前取消 requestor 中所有未完成的请求
   */
  Vue.prototype.$requestor.cancelAll();
  Vue.prototype.$message({
    type: 'error',
    message: '请先登录'
  });
  Vue.prototype.$requestor.removeToken();
  localStorage.removeItem('app_token');
  store.state.userInfo = {}
  store.state.isLogin = false
  store.state.loading = false
  // /**
  //  * 传递触发 401 的页面参数以便回跳
  //  */
  // const referer = encodeURIComponent(router.currentRoute.fullPath);
  // router.push({
  //   path: "/authorize",
  //   query: {
  //     referer
  //   }
  // });
};

Vue.prototype.$requestor.errorHandle[404] = () => {
  // Vue.prototype.$layer.alert("404:资源不存在");
  Vue.prototype.removeToken()
  Vue.prototype.$message({
    type: 'warning',
    message: '404:资源不存在'
  });
  store.state.loading = false
};

Vue.prototype.$requestor.errorHandle[500] = () => {
  // Vue.prototype.$layer.alert("500:服务器内部异常");
  Vue.prototype.$message({
    type: 'warning',
    message: '500:服务器内部异常'
  });
  store.state.loading = false
};

Vue.prototype.$requestor.errorHandle["timeout"] = () => {
  // Vue.prototype.$layer.alert("请求超时");
  Vue.prototype.$message({
    type: 'warning',
    message: '请求超时'
  });
  store.state.loading = false
};

Vue.prototype.$requestor.errorHandle["network"] = () => {
  // Vue.prototype.$layer.alert("网络异常");
  Vue.prototype.$message({
    type: 'warning',
    message: '网络异常'
  });
  store.state.loading = false
};

/**
 * 注入全局过滤器
 */
Vue.use(GlobalFilter);

/**
 * 注入 api 模块
 * api 模块封装了大部分的后台接口请求
 */
Vue.prototype.$api = api;

/**
 * 注入 tools 模块
 */
Vue.prototype.$tools = tools;

Vue.prototype.$requestor.setBaseUrl(process.env.VUE_APP_BASE_URL);


/**
 * 给 Vue 注入 setToken 方法用于为应用设置 token
 * Note:此应用提供多种授权方式，任何一种授权方式拿到 token 后都应该调用 setToken 进行 token 的设置，而不是直接给 requestor 设置 token
 */
Vue.prototype.setToken = value => {
  Vue.prototype.$requestor.setToken(value);
  localStorage.setItem('app_token', value);
};

Vue.prototype.removeToken = value => {
  Vue.prototype.$requestor.removeToken(value);
  localStorage.removeItem('app_token');
};

if(localStorage.getItem('app_token')) {
  Vue.prototype.setToken(localStorage.getItem('app_token'))
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
