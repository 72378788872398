import axios from "axios";
import md5 from "crypto-js/md5";

class RequestManager {
  constructor() {
    this.container = new Map();
  }

  _key(data) {
    const base = data.baseURL;
    let url = data.url;
    url = base + url.replace(base, "");

    return md5(
      JSON.stringify([data.method, url, data.params, data.data])
    ).toString();
  }

  record(request, cancel) {
    this.container.set(this._key(request), cancel);
  }

  forget(request) {
    this.container.delete(this._key(request));
  }

  cancel(request) {
    const key = this._key(request);
    const cancel = this.container.get(key);
    if (cancel != undefined) {
      cancel("cancel for repeating");
      this.container.delete(key);
    }
  }

  cancelAll() {
    for (let [_, cancel] of this.container) {
      cancel("cancel for all");
    }

    this.container.clear();
  }
}

/**
 * RequestManager 用于登记网络请求及对应的 CancelToken
 * 目的是为了能够取消处于 pending 状态的请求
 */
const requestManager = new RequestManager();

const instance = axios.create({
  timeout: 10000,
  withCredentials: false
});

/**
 * 取消所有未完成的请求
 */
instance.cancelAll = requestManager.cancelAll.bind(requestManager);

/**
 * 调用者可以通过设置这个对象的键值来处理 http 请求错误
 */
instance.errorHandle = {
  400: undefined,
  401: undefined,
  404: undefined,
  500: undefined,
  timeout: undefined,
  network: undefined
};

/**
 * 设置 base url，所有的相对路径都会相对于 base url
 * @param {string} value base url
 */
instance.setBaseUrl = function(value) {
  this.defaults.baseURL = value;
};

/**
 * 设置 JWT，一旦设置，后续所有的请求都会自动带上 JWT
 * @param {string} value JWT
 */
instance.setToken = function(value) {
  this.defaults.headers.common["Authorization"] = value;
};

instance.removeToken = function(value) {
  this.defaults.headers.common["Authorization"] = value;
};

/**
 * get 请求
 * @param {string} url
 * @param {object} config
 */
instance._get = async function(url, config) {
  const { data } = await this.get(url, config);
  return data;
};

/**
 * post 请求
 * @param {string} url
 * @param {object} body
 * @param {object} config
 */
instance._post = async function(url, body, config) {
  const { data } = await this.post(url, body, config);
  return data;
};

/**
 * 设置请求拦截器
 */
instance.interceptors.request.use(
  function(config) {
    // requestManager.cancel(config); //取消重复的请求
    config.cancelToken = new axios.CancelToken(cancel => {
      requestManager.record(config, cancel);
    }); //为新的请求生成 cancel token, 并记录

    /**
     * 请求开始，调用 atBegin handle
     */
    this.atBegin && this.atBegin();

    return config;
  }.bind(instance),
  error => {
    return Promise.reject(error);
  }
);

/**
 * 设置响应拦截器
 */
instance.interceptors.response.use(
  function(response) {
    requestManager.forget(response.config); //将已经完成的请求从 request manager 中移除

    /**
     * 请求结束，调用 atOver handle
     */
    this.atOver && this.atOver();

    return response;
  }.bind(instance),
  function(error) {
    let handle = undefined;

    if (error.response != undefined) {
      handle = this.errorHandle[error.response.status];
    }

    if (error.message.startsWith("timeout")) {
      handle = this.errorHandle.timeout;
    }

    if (error.message.startsWith("Network")) {
      handle = this.errorHandle.network;
    }

    /**
     * 调用错误处理 handle
     */
    handle && handle(error);

    /**
     * 请求结束，调用 atOver handle
     */
    this.atOver && this.atOver();

    return Promise.reject(error);
  }.bind(instance)
);

export default instance;
