import requestor from "@/utils/requestor";

export default {
  /**
   * 搜索
   */
  async search(data) {
    return await requestor._post("/api/auction/search/index", data)
  },
  /**
   * 获取货币列表
   */
  async getCurrencyList() {
    return await requestor._get("/api/common/getCurrencyList")
  },
  /**
   * 获取网站列表
   */
  async getSiteList() {
    return await requestor._get("/api/auction/site/getOptions")
  },
  /**
   * 获取统计
   */
  async getCount(data) {
    return await requestor._post("/api/auction/search/count", data)
  },
  /**
   * 获取详情
   */
  async getSearchDetail(data) {
    return await requestor._post("/api/auction/search/detail", data)
  }
}