import tools from "./tools";

const filters = {
  dateFormat: (value, format = "yyyy-MM-dd") => {
    if (typeof value !== "object") {
      value = new Date(value);
    }

    return tools.dateFormat(value, format);
  },
  /**
   * 字符串中部缩略
   * @param {string} value 要缩略的字符串
   * @param {int} front 首部要显示的字符数
   * @param {int} tail 尾部要显示的字符数
   */
  centerEllipsis(value, front = 4, tail = 6, placeholder = "...") {
    value = String(value);
    return value.slice(0, front) + placeholder + value.slice(-tail);
  },
  date: (value,format = "Y-m-d") => {
    return tools.date(format, value);
  },
};

export default {
  install: vue => {
    for (let key in filters) {
      vue.filter(key, filters[key]);
    }
  }
};
